@import "./../shared.scss";

.visibleHoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.visibleHoverHotspot:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

.plenaryHoverHotspot {
  top: 54.35%;
  left: 12.7%;
  width: 22.7%;
}

.ondemandHoverHotspot {
  top: 55.9%;
  left: 64.65%;
  width: 34.9%;
}

.networkingHoverHotspot {
  top: 49.98%;
  left: 54.22%;
  width: 11.7%;
}


