@import "./../shared.scss";
@import "./Pre-Login.module.scss";

.loginModal {
  @extend .preLoginModal;
}

.loginModalInnerDiv {
  @extend .preLoginModalInnerDiv;
}

.signInText {
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 2%;
  color: $color-login-text;
}

@media (max-width: 550px) {
  .signInText {
    font-size: 4vw;
  }
}

.loginButton {
  @extend .button;
  width: 48%;
  border-radius: 10% / 50%;
}