@import "./../shared.scss";

.styledNavBarDiv {
  // Use this to leave space for logout/profile picture
  width: 100%;
  background-color: $color-menu-bar-feature;
  height: 65px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 50000;
  transition: top 1s;
  background-repeat: repeat-x;
  background-size: 100% auto;
  //background-image: url('https://lu-arm-big-picture-2022.s3.eu-west-2.amazonaws.com/visualAssets/en/topNavBar.png');
}

.styledNavBarDivHidden {
  // Use this to leave space for logout/profile picture
  width: 100%;
  background-color: $color-menu-bar-feature;
  height: 65px;
  position: fixed;
  top: -100px;
  left: 0px;
  z-index: 50000;
  transition: top 1s;
}

.styledNavbar {
  // Removed to allow use of background image
  //background-color: $color-menu-bar-background;
  background-position: 0% 45%;
  background-size: 100%;
  color: $color-menu-bar-text !important;
  border-bottom: 50px;
  margin-top: 5px;
  margin-bottom: 45px;

  border-color: $color-menu-bar-feature;
  width: 85%;
  border: 0px;
  border-radius: 0px;
  justify-content: left;
}

@media (max-width: 1199px) {
  // Add back in background colour for mobile/dropdown version
  .styledNavbar {
    background-color: $color-menu-bar-background;;
  }
  .styledNavBarDiv {
    background-size: 0% 0%;
    background-color: $color-menu-bar-background;
  }
}

.navBarToggle {
  color: $color-menu-bar-text;
}

.styledNavItem {
  color: $color-menu-bar-text !important;
  margin-right: 20px;
  height: 40px;
  text-shadow: 2px 2px black;
}

.styledDropdownNavItem {
  color: $color-menu-bar-text !important;
  background-color: $color-menu-bar-feature;
}

.styledDropdownNavItem:hover {
  background-color: $color-menu-bar-feature-highlight;
}

.styledDropdownTitle {
  margin-right: 20px;
  text-shadow: 2px 2px black;
}

.navBarCollapse {
  color: $color-menu-bar-text;
}

.styledDropdown {
  color: $color-menu-bar-text !important;
}

.navBarTopLeftIcon {
  height: 35px;
  margin-right: 30px;
}

.modalClose {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.userProfileArea {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-right: 5px;
  width: 15%;
  //background-color: $color-menu-bar-background;
  height: 56px;
}

.userName {
  margin-top: 5px;
  text-align: right;
  color: $color-menu-bar-text;
}

@media (max-width: 1450px) {
  .userName {
    font-size: 0.95em;
  }
  .styledNavItem {
    margin-right: 10px;
    font-size: 0.95em;
    height: 40px;
  }
  .styledDropdownTitle {
    font-size: 0.95em;
  }
}

@media (max-width: 1350px) {
  .userName {
    font-size: 0.9em;
  }
  .styledNavItem {
    margin-right: 3px;
    font-size: 0.9em;
    height: 40px;
  }
  .styledDropdownTitle {
    font-size: 0.9em;
  }
}

@media (max-width: 1270px) {
  .userName {
    font-size: 0.8em;
  }
  .styledNavItem {
    margin-right: 3px;
    font-size: 0.8em;
    height: 40px;
  }
  .styledDropdownTitle {
    font-size: 0.8em;
  }
}

@media (max-width: 1050px) {
  .userName {
    font-size: 0.7em;
  }
}

@media (max-width: 900px) {
  .userName {
    visibility: hidden;
  }
}

.userProfilePictureArea {
  position: relative;
  float: right;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  margin-left: 5px;
}

.userProfilePictureBackground {
  position: absolute;
  width: 100%;
}

.userProfilePicture {
  position: absolute;
  width: 98.2%;
  left: 0.9%;
  top: 0.9%;
  height: 99.1%;
}

.logoutButton {
  @extend .highlightedButton;
  color: white;
  width: 60px;
  padding: 2px;
}

.accessibleHeading {
  position: absolute;
  font-size: 0.1em;
  color: white;
}

