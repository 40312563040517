@import "./../shared.scss";

.preLoginModal :global {
  background-color: $color-login-background;
  color: $color-login-text;
  position: absolute;
  top: 10%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  height: 650px;
  z-index: 2000;
  animation: 1s ease-out 0s fadeIn;
  border-radius: 2% / 1.5%;
  overflow: hidden;
  box-shadow: 10px 10px 25px 8px #444444
}

@media (max-width: 550px) {
  .preLoginModal :global {
    width: 90%;
    height: 600px;
    padding-top: 125%;
    border-radius: 2%;
  }
}

.preLoginModalInnerDiv {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 550px) {
  .preLoginModalInnerDiv {
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.preLoginBoxLogo {
  width: 50%;
  margin-top: 0%;
  margin-bottom: 7%;
}

.helpTab {
  color: $color-login-text;
  position: absolute;
  right: 3%;
  top: 0%;
  width: 146px;
  cursor: pointer;
  font-size: 30px;
}

@media (max-width: 550px) {
  .helpText {
    font-size: 6vw;
  }
}

.textEntry {
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  border: 1px lightgrey solid;
  color: $color-login-text;
  margin-bottom: 10px;

}

.titleText {
  width: 100%;
  text-align: left;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 0%;
  color: $color-login-feature-text;
}

@media (max-width: 550px) {
  .titleText {
    font-size: 8vw;
  }
}

.loginButtonsRow {
  width: 100%;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

.loginBoxFooter {
  position: absolute;
  bottom: -7%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  //display: none;
  z-index: -1;
}

@media (max-width: 450px) {
  .loginBoxFooter {
    display: none;
  }
}

@keyframes :global(fadeIn) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}