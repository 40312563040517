@import "sharedStyles/SharedColors.module.scss";
@import "sharedStyles/SharedModal.module.scss";
@import "sharedStyles/SharedMobile.module.scss";

// Global Definitions
// Only used for 16:9 pages e.g. login and lobby but not scrollable gallery pages
.masterPageDiv {
  padding-top: 56.25%;
  width: 100%;
  height: 0;
  position: absolute;
}

// Dummy comment

// Background image for 16:9 pages
.fullScreenBackground {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: src 1s;
}

// Hide background images on mobile
@media (max-width: 550px) {
  .fullScreenBackground {
    display: none;
  }
}

// Beehive button styling
@mixin buttonMixin {
  border-radius: 15% / 50%;
  border: 0;
  width: 150px;
  // Plays differently on Firefox
  //aspect-ratio: 5;
  background-color: $color-button-standard;
  color: white;
  font-size: 0.9em;
  padding: 10px;
}

.button {
  @include buttonMixin;
}

.highlightedButton {
  @include buttonMixin;
  background-color: $color-button-highlighted;
}

.highlightedButton {
  @include buttonMixin;
  background-color: $color-button-highlighted;
}

.ctaButton {
  @include buttonMixin;
  background-color: $color-arm-yellow;
  color: black;
}

.orangeFeatureButton {
  @include buttonMixin;
  background-color: $color-arm-orange;
}

@media (max-width: 550px) {
  .button {}

  .highlightedButton {}
}


// Admin styling
.adminContentArea {
  background-color: white;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  padding-left: 50px;
  padding-bottom: 100px;
  padding-right: 50px;
}

// Hover Definitions
.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

// Button styles
.successButton,
.successButton:hover,
.successButton:active,
.successButton:visited {
  background-color: $color-button-success  !important;
  border-color: $color-button-success  !important;
  color: $color-button-text;
}

.primaryButton,
.primaryButton:hover,
.primaryButton:active,
.primaryButton:visited {
  background-color: $color-button-primary  !important;
  border-color: $color-button-primary  !important;
  color: $color-button-text;
}
