@import "./../shared.scss";

// Section selection panel
.sectionSelectionPanel {
  background-color: $color-light-grey-background;
  padding: 20px 10px 20px 10px;
}

.sectionSelectionButton {
  @extend .button;
  margin-left: 2%;
}

.helpChatBoxArea {
  position: relative;
  width: 99%;
  // Chat area is natively 400px tall
  height: 400px;
  margin-bottom: 20px;
}
