@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}


.visibleHoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.visibleHoverHotspot:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

.lobbyHoverHotspot {
  top: 62.7%;
  left: 76.7%;
  width: 22.9%;
  z-index: 5000;
}

.goToPresentation {
  position: absolute;
  opacity: 0;
  //background-color: green;
  top: 30%;
  left: 0%;
  width: 45%;
  height: 50%;
  z-index: 5000;
}

.leavePresentation {
  position: absolute;
  opacity: 0;
  //background-color: red;
  top: 90%;
  left: 20%;
  //width: 60%;
  //height: 10%;
  width: 0;
  height: 0;
  z-index: 5000;
}

.iFrameDiv {
  position: absolute;
  width: 66%;
  height: 67%;
  left: 33.5%;
  top: 15%;
}

.widgetContainerDiv {
  position: absolute;
  top: 12%;
  left: 6.5%;
  width: 25%;
  height: 70%;
  //background-color: red;
}
