@import "./../shared.scss";
@import "./Pre-Login.module.scss";

.loginHelpModal {
  @extend .preLoginModal;
  // Show on top of login modal
  width: 700px;
  z-index: 3000;
}

.loginHelpModalInnerDiv {
  @extend .preLoginModalInnerDiv;
  display: flex;
  flex-direction: column;
}

.loginHelpTextArea {
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 20px;
}