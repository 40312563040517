@import "./../shared.scss";

.leaderboardTable {
  margin-top: 4%;
  width: 100%;
}

.leaderTable {
  width: 100%;
}

.nameCell {
  color: $color-modal-text;
  font-size: 1.2em;
}

.pointsCell {
  color: $color-modal-text;
  font-size: 1.5em;
  text-align: right;
}

.numberCircle {
  border-radius: 5%;
  width: 105px;
  height: 50px;
  padding-top: 7px;

  background: $color-menu-bar-feature;
  border: 0px solid #666;
  text-align: center;
  float: right;
  color: white;
}

.horizRule {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: px;
  margin-bottom: 20px;
}

.myPoints {
  color: $color-modal-text;
  margin-left: 50px;
  font-size: 2em;
}