@import "./../shared.scss";
@import "./Pre-Login.module.scss";

.requestPasswordResetModal {
  @extend .preLoginModal;
  // Show on top of login modal
  z-index: 3000;
}

.requestPasswordResetModalInnerDiv {
  @extend .preLoginModalInnerDiv;
}
