@import "./../shared.scss";

.background {
  background-color: white;
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.remoteControlArea {
  padding-top: 120px;
  padding-left: 50px;
}
