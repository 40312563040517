@import "./../shared.scss";

.daySelectionPanel {
  background-color: white;
  padding-left: 2%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.daySelectionButton {
  @extend .button;
  margin-left: 3%;
}

// Customise scrollable area to provide visible frame inset from main modal
.scrollableArea {
  @extend .scrollableArea;
  margin: 0% 6% 2% 5%;
  border: 1px solid grey;
  padding-top: 0;
}

.agendaTableContainer {
  //background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
}


