@import "./shared.scss";

/* Global font face selection */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
* {
  font-family: "Lato", sans-serif;
}

body {
// Sets background colour for all pages
  background-color: $color-master-background;
}

@media (max-width: 699px) {
  body {
    //background-color: white;
  }
}

/* Global bootstrap customisation */

.btn-primary,
.btn-primary:hover,
.btn-primary:visited {
  background-color: $color-button-primary !important;
  border-color: $color-button-primary !important;
  color: $color-button-text;
}

.btn-primary.active {
  background-color: $color-button-success !important;
  border-color: $color-button-success !important;
  color: $color-button-text;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: $color-button-secondary !important;
  border-color: $color-button-secondary !important;
  color: $color-button-text;
}

.btn-success,
.btn-success:hover,
.btn-success:active,
.btn-success:visited {
  background-color: $color-button-success !important;
  border-color: $color-button-success !important;
  color: $color-button-text;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:visited {
  background-color: $color-light-blue !important;
  border-color: $color-light-blue !important;
  color: $color-button-text;
}

.navbar-light .navbar-nav .nav-link {
  color: $color-menu-bar-text;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: $color-menu-bar-text;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: $color-menu-bar-text;
}

.dropdown-menu.show {
  background-color: $color-menu-bar-feature;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler {
  border-color: white;
}

/* Shared classes */

.close3DButton {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
  border-radius: 27px;
  border: 1px solid #ffffff;
}

.soundOn3DButton {
  position: fixed;
  top: 100px;
  width: 50px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
  border-radius: 27px;
  border: 0px solid #ffffff;
}

// Other non scss css
//::placeholder {
//  color: white;
//  margin-left: 10px;
//}

/* Demo functionality classes not sorted into modules */

.remoRoom {
  width: 250px;
  height: 250px;
  background-color: lightblue;
  border-radius: 20px;
  margin-top: 50px;
  padding-top: 105px;
}

.remoPerson {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: green;
  border-color: white;
  border-width: 5px;
  border-style: solid;
}

.remoLeft {
  position: absolute;
  top: 150px;
  left: 13px;
}

.remoBottom {
  position: absolute;
  top: 250px;
  left: 115px;
}

.remoRight {
  position: absolute;
  top: 150px;
  left: 220px;
}

.remoTop {
  position: absolute;
  top: 50px;
  left: 115px;
}

.remoLabel {
  font-size: 1.5em;
}

.lobbyPopUp {
  width: 500px;
  height: 300px;
  background-color: lightGrey;
  position: absolute;
  z-index: 2000;
  left: 500px;
  top: 500px;
}

.slidoIframe {
  margin-left: 200px;
  width: 500px;
}

.slidoPresentDiv {
  margin-top: 50px;
  width: 600px;
  height: 350px;
  display: inline-block;
  margin-right: 50px;
}

.videoLibraryModal {
  visibility: true;
}

/* Below are common to whole app where there is a full width background */

#zmmtg-root {
  visibility: hidden;
}

.imageNavDiv {
  padding-top: 56%;
  width: 100%;
  height: 0;
  position: absolute;
}

.imageNavBackground {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: src 1s;
}

/* The below should stay for now to avoid CSS naming issues within modules

/* ImageNav CSSTransition CSS */
.imageNavBackground-enter {
  opacity: 0;
}
.imageNavBackground-enter-active {
  opacity: 1;
  transition: opacity 1500ms;
}
.imageNavBackground-exit {
  opacity: 1;
}
.imageNavBackground-exit-active {
  opacity: 0;
  transition: opacity 1500ms;
}

/* Transition group slide in from right */
.slideFromRightCSSTransition-enter {
  transform: translateX(200%);
}

.slideFromRightCSSTransition-enter-active {
  transform: translateX(0%);
  transition: all 500ms linear;
}

.slideFromRightCSSTransition-exit {
  transform: translateX(0%);
}

.slideFromRightCSSTransition-exit-active {
  transform: translateX(200%);
  transition: all 500ms linear;
}

/* Shrink profile image CSS transition */
.shrinkProfileIconCSSTransition-enter {
  width: 0;
}

.shrinkProfileIconCSSTransition-enter-active {
  opacity: 1;
  transition: all 500ms linear;
}

.shrinkProfileIconCSSTransition-exit {
  opacity: 1;
}

.shrinkProfileIconCSSTransition-exit-active {
  width: 0;
  transition: all 500ms linear;
}

// Fade in
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

/* Used by router transitions to prevent doubling up of outgoing and incoming */
div.transition-group {
  position: relative;
}

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
