@import "./../shared.scss";

.proportionalDiv {
  padding-top: 53.7%;
  width: 100%;
  //height: 0;
}

.backgroundImage {
  width: 100%;
  position: absolute;
  top: 0px;
  min-width: 1200px;
}

@keyframes :global(slideInFromBottom) {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes :global(slideInFromLeft) {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes :global(fadeIn) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loginBox :global {
  color: white;
  position: absolute;
  top: 20%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  height: 580px;
  width: 480px;
  background-image: url("https://lu-arm-big-picture-2022.s3.eu-west-2.amazonaws.com/customLoginPage/loginBoxThin.png");
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 2000;
  animation: 1s ease-out 0s fadeIn;
}


@media (max-width: 1200px) {
  .armWhiteLogo {
    visibility: hidden;
  }
}

.lockUpWhite {
  width: 280px;
  margin-left: 100px;
  margin-right: auto;
  margin-top: 3%;
  margin-bottom: 5%;
}

.armWhiteLogo {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 80px;
}

.loginTextEntry {
  margin-left: 12%;
  width: 350px;
}

.signInText {
  font-size: 1.3em;
  font-weight: 0.1;
  padding-bottom: 50px;
}

.textEntry {
  width: 350px;
  background: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0);
  color: white;
}

.submitButton {
  width: 200px;
}

@media (max-width: 600px) {
  .loginBox {
    //height: 500px;
    width: 90vw;
    background-image: url("https://lu-arm-big-picture-2022.s3.eu-west-2.amazonaws.com/customLoginPage/loginBoxThin.png");
  }
  .signInText {
    font-size: 5vw;
    padding-bottom: 3%;
  }
  .lockUpWhite {
    width: 50%;
    margin-left: 10%;
  }
  .loginTextEntry {
    width: 80%;
  }
}

.requestPasswordResetBox :global {
  background-color: $color-arm-dark-blue;
  color: white;
  position: absolute;
  top: 20%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  height: 480px;
  width: 480px;
  background-repeat: no-repeat;
  z-index: 3000;
  animation: 1s ease-out 0s fadeIn;
  background-image: url("https://lu-arm-big-picture-2022.s3.eu-west-2.amazonaws.com/customLoginPage/loginBoxThin.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.forgottenPasswordLink {
  cursor: pointer;
}