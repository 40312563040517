@import "./../shared.scss";

.mobileLobbyInstructionText {
  margin-top: 2%;
  width: 100%;
  color: black;
  text-align: center;
  font-size: 5vw;
}

.lobbyIconGrid {
  padding: 3%;
  padding-top: 0%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

@media (min-width: 500px) {
  .lobbyIconGrid {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

.lobbyIconGridElement {
  padding: 4% 5% 0% 5%;
}

.lobbyIcon {
  width: 70%;
  margin-left: 15%;
  cursor: pointer;
}

.lobbyIconText {
  margin-top: 5%;
  text-align: center;
  color: #888888;
  font-size: 4vw;
}

@media (min-width: 500px) {
  .lobbyIconText {
    font-size: 2.5vw;
  }
}
