@import "./../shared.scss";

.onDemandVideoAspectDiv {
  margin-left: 5%;
  margin-top: 5%;
  width: 90%;
  padding-bottom: 56.25%;
  position: relative;
}

.onDemandVideoDiv {
  position: absolute;
  width: 100%;
  height: 100%;
}

.infoArea {
  margin:0% 5% 5% 5%;
  text-align: center;
}

.contentTitle {
  color: $color-gallery-feature-text;
  font-size: 1.5em;
  font-weight: 700;
}

.onDemandPresenterNameText {
  font-size: 1.3em;
  color: $color-gallery-feature-text;
}

.aboutTextDiv {
  margin-top: 20px;
}

.aboutText {
  font-size: 1em;
}

.urlButton {
  border-radius: 20px 20px;
  width: 100%;
  //height: 80px;
  color: white;
  font-size: 1.5em;
  background-color: $color-arm-dark-blue;
  text-align: left;
  padding: 10px;
  padding-left: 20px;
  //box-shadow: 10px 10px 8px #888888;
}

@media (max-width: 900px) {
  .urlButton {
  font-size: 1.4em;
  height: auto;
  }
}

.linkDiv {
  padding: 15px;
}

.linkArrow {
  float: right;
  margin-right: 10px;
  width: 30px;
}