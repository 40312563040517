@import "./../shared.scss";
@import "./../sharedStyles/SharedGallery.module.scss";

.contentContainerDiv {
  position: absolute;
  padding-top: 0%;
  top: 185px;
  width: 100%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}

// Div for gallery style content items area
.contentSegmentsArea {
  padding: 5%;
  padding-top: 2%;
  width: 100%;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(560px, 1fr));
  z-index: 3000;
  justify-items: center;
}


// Class for visual on-demand area content area
.onDemandItemsArea {
  position: absolute;
  background-color: white;
  padding: 1%;
  left: 8%;
  width: 72%;
  top: calc(10.5vw + 80px);
  height: calc(41vw - 80px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  z-index: 3000;
}

// Div holding tile for single content item
.contentContainer {
  background-color: $color-gallery-item-background;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 550px;
  height: 250px;
  box-sizing: border-box;
  z-index: 3220;
  padding: 0px;
  max-height: 340px;
  // Relative for benefit of positioning more button
  position: relative;
}

.contentContainerUpper {
border-bottom: 1px solid #bbbbbb;
margin: 12px;
padding-bottom: 5px;
font-weight: 700;
color: $color-gallery-feature-text;
font-size: 1.2em;
}

.contentContainerLower {
padding: 15px;
display: flex;

}

.contentVideoArea {
  flex: 2;
}

.onDemandVideoDiv {
  width: 100%;
}

.contentTextDiv {
  padding-left: 15px;
  flex: 3;
}

.speakerTitle {
  color: $color-gallery-feature-text;
  font-weight: 700;
}

.speakerName {
  color: black;
}

.buttonPositionDiv {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.dayTitle {
  margin-top: 50px;
  margin-left: 40px;
  font-size: 2em;
}

.horizRule {
  border-top: 2px solid $color-light-grey;
}




// Classes for visual version of on-demand area

.exitHoverHotspot {
  position: absolute;
  opacity: 0;
  top: 15%;
  left: 1%;
  width: 5%;
  height: 10%;
  z-index: 5000;
}

.visualTitleBar {
  position: absolute;
  top: 18.5%;
  left: 7%;
  width: 73.6%;
  background-color: $color-modal-title-bar-background;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 30px;
  color: $color-modal-title-bar-text;
  background-size: 100% 100%;
}

.visualPageTitle {
  display: inline;
  color: white;
}
