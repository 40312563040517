.mobileNotification {
  background-color: white;
  position: fixed;
  bottom: 70px;
  width: 90%;
  margin-left: 5%;
  height: 70px;
  padding: 1% 2% 2% 2%;
  z-index: 10000;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
}

.closeArea {
  cursor: pointer;
}

.notificationClose {
  margin-top: 3px;
  width: 50px;
  margin-right: 10px;
}

.messageArea {
  overflow-y: auto;
  font-size: 18px;
}
