@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.lobbyHoverHotspot {
  top: 88.3%;
  left: 33.6%;
  width: 22.9%;
  height: 10%;
}

.smallBreakout1HoverHotspot {
  top: 73%;
  left: 58%;
  width: 27%;
  height: 25%;
}

.smallBreakout2HoverHotspot {
  top: 75%;
  left: 15%;
  width: 19%;
  height: 21.5%;
}

.smallBreakout3HoverHotspot {
  top: 45%;
  left: 10%;
  width: 22%;
  height: 22%;
}

.smallBreakout4HoverHotspot {
  top: 15%;
  left: 60%;
  width: 25%;
  height: 25%;
}

.largeBreakoutHoverHotspot {
  top: 68%;
  left: 2%;
  width: 40%;
  height: 28%;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}
