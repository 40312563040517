@import "./../shared.scss";

// Max 2 user classes first

// Single remote user - preview container
.singleRemoteUserLocalMediaDiv {
  position: absolute;
  left: 0px;
  top: 100px;
  width: 400px;
  z-index: 2000;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 0px 30px 20px $color-beehive-blue;
  background-color: $color-beehive-blue;
}

// Single remote user - preview video
.singleRemoteUserLocalMediaDiv video {
  position: absolute;
  top: -1px;
  height: 405px;
  left: -70px;
  border: 1px solid #ffffff;
  z-index: 200;
}

// Single remote user - remote user label
.singleRemoteUserLocalMediaDiv h1 {
  visibility: hidden;
  font-size: 2em;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 600;
  top: 270px;
  left: 0px;
}

// Single remote user - remote media container
.singleRemoteUserRemoteMediaDiv {
  position: absolute;
  right: 0px;
  top: 100px;
  width: 400px;
  height: 400px;
  z-index: 2000;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 0px 30px 20px $color-beehive-blue;
  background-color: $color-beehive-blue;
}

// Single remote user - remove media video
.singleRemoteUserRemoteMediaDiv video {
  position: absolute;
  top: -1px;
  height: 405px;
  left: -70px;
  border: 1px solid #ffffff;
  z-index: 200;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}

// Single remote user - remote user label
.singleRemoteUserRemoteMediaDiv h1 {
  font-size: 2em;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 600;
  top: 270px;
  left: 0px;
  visibility: hidden;
}

// ***************************Now multiple user classes below

.participantsBar {
  position: fixed;
  //width: 100%;
  background-color: $color-very-light-grey-background;
  height: 325px;
  bottom: 0px;
  overflow-x: scroll;
  width: 2000px;
}

// Multiple remote user - preview container
.multipleRemoteUserLocalMediaDiv {
  position: absolute;
  left: 22px;
  padding: 20px;
  top: 25px;
  height: 275px;
  width: 290px;
  background-color: $color-dark-grey;
}

// Single remote user - preview video
.multipleRemoteUserLocalMediaDiv video {
  width: 250px;
  border: 1px solid #ffffff;
  z-index: 2000;
}

// Remote participant container for >2 people
.multipleRemoteUserRemoteMediaDiv {
  position: absolute;
  left: 310px;
  top: 25px;
  min-width: 300px;
  height: 275px;
  z-index: 1000;
  overflow-x: scroll;
  width: 2000px;
}

.remoteParticipant {
  height: 275px;
  padding: 20px;
  display: inline-block;
  margin-left: 20px;
}

// Remote participant video for >2 people
.multipleRemoteUserRemoteMediaDiv video {
  width: 250px;
  border: 1px solid #ffffff;
  z-index: 2000;
}

// Single remote user - remote user label
.multipleRemoteUserRemoteMediaDiv h1 {
  font-size: 2em;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 600;
}

.centralContentArea {
  position: absolute;
  top: 210px;
  left: 0px;
  right: 0px;
  width: 1000px;
  height: 800px;
  margin: auto;
}

.connectDiv {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  background-color: $color-light-grey;
  top: 500px;
  z-index: 500;
  border-radius: 20px;
}

.connectButton {
  margin: 20px;
  margin-left: 20px;
}

.disconnectButton {
  margin: 20px;
  margin-right: 20px;
  float: right;
}

.namePanel {
  position: absolute;
  background-color: $color-light-grey;
  left: 50px;
  width: 350px;
  height: 230px;
  top: 100px;
  border-radius: 50px;
  z-index: 50;
  visibility: hidden;
}

.participantname {
  z-index: 200;
  color: $color-dark-grey;
}

.connectHint {
  position: absolute;
  top: 200px;
  width: 100%;
  text-align: center;
}

.textChatArea {
  position: absolute;
  top: 670px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 920px;
}
