@import "./../shared.scss";

// Scroll element
// Fill whole of remaining space
.scrollableArea {
  // Scale to fill available space
  flex-grow: 1;
  margin-top: 0%;
  overflow-y: auto;
}

.pageNavColumn {
  background-color: $color-profile-stages-column-background;
  width: 150px;
  padding-bottom: 100%;
  float: left;
  margin-right: 3%;
}

// Hide progress indicators at low screen resolution
@media (max-width: 700px) {
  .pageNavColumn {
    width: 0px;
  }
}

.sessionSelectionArea {
  padding-left: 3%;
  padding-right: 3%;
}

.subheading {
  font-size: 1.8em;
}

.smallSubheading {
  font-size: 1.2em;
}

.statusIcon {
  width: 110px;
  float: right;
  margin-top: 60px;
}

.sessionOption {
  font-size: 0.75em;
  width: 150px;
  border: 2px black solid;
  height: 100px;
}

@media (max-width: 700px) {
  .sessionOption {
    width: 25vw;
  }
}

.buttonArea {
  margin-bottom: 10%;
}

.bottomRightButtons {
  float: right;
  margin-left: 10px;
  margin-top: 10px;
}
