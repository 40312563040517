// Customised version with padding relative to standard version in shared.scss
// was outerBackgroundDiv
.galleryBackgroundDiv {
  position: fixed;
  //background-color: $color-background;
  background-color: white;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.searchIcon {
  width: 50px;
  height: 50px;
  z-index: 30000;
  left: 0;
  top: 0;
}


// Styles for tab bar
.tabBar {
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: #cccccc;
  margin-top: 65px;
  z-index: 4000;
}

.backLink {
  float: left;
  color: black;
}

.tabBackDiv {
  position: fixed;
  left: 30px;
  top: 75px;
  height: 40px;
  width: 250px;
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  padding: 8px;
  font-size: 1.2em;
}

.backArrow {
  margin-left: 5px;
  //margin-top: 0px;
  margin-right: 5px;
  width: 20px;
  float: left;
}

.titleAndSearchBar {
  background-color: white;
  position: fixed;
  top: 115px;
  left: calc(2% + 20px);
  right: calc(2% + 20px);
  z-index: 4000;
  border-bottom: 1px solid black;
}

.pageTitle {
  color: $color-gallery-feature-text;
  margin-top: 15px;
  margin-bottom: 15px;
  float: left;
}

@media (max-width: 700px) {
  .pageTitle {
    font-size: 7vw;
  }
}

.pageInstruction {
  margin-top: 15px;
}

.searchTitleArea {
  position: absolute;
  top: 13px;
  width: 40%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.searchBox {
  float: right;
  width: 25%;
  margin-top: 25px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: right;
  border: 1px solid #cccccc;
}

@media (max-width: 900px) {
  .searchTitleArea {
    display: none;
  }

  .searchBox {
    width: 35%;
  }
}