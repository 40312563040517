@import "./../shared.scss";

.registerModal :global {
  background-color: $color-login-background;
  color: $color-login-text;
  position: absolute;
  top: 10%;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  z-index: 3000;
  animation: 1s ease-out 0s fadeIn;
  border-radius: 2% / 1.5%;
  box-shadow: 10px 10px 25px 8px #444444
}

@media (max-width: 800px) {
  .registerModal :global {
    width: 90%;
    border-radius: 4%;
  }
}

.registerModalInnerDiv {
  background-color: $color-login-background;
  width: 100%;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 1%;
  border-radius: 4% / 3%;
  min-height: 650px;
}

.titleText {
  width: 100%;
  text-align: left;
  font-size: 40px;
  font-weight: 500;
  margin-top: 0%;
  margin-bottom: 0%;
  color: $color-login-feature-text;
}

@media (max-width: 599px) {
  .titleText {
    // Shuffle title down under help tab button on mobile
    margin-top: 4%;
  }
}