@import "./../shared.scss";

.answerArea {
  background-color: white;
  height: 100%;
  margin-left: 50px;
  margin-top: 120px;
  margin-right: 150px;
  min-height: 700px;
  padding: 20px;
  padding-bottom: 100px;
}
