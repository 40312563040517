@import "./../shared.scss";

.connectHint {
  width: 90%;
  margin-left: 5%;
  text-align: center;
  color: black;
}

.connectDiv {
  margin-left: 10%;
  width: 80%;
  background-color: $color-light-grey;
  z-index: 500;
  border-radius: 20px;
}

.connectButton {
  margin: 20px;
  margin-left: 20px;
}

.disconnectButton {
  margin: 20px;
  margin-right: 20px;
  float: right;
}

// Single remote user - preview container
.singleRemoteUserLocalMediaDiv {
  width: 30%;
  margin-left: 35%;
  margin-top: 5%;
  z-index: 2000;
  //overflow: hidden;
  background-color: $color-beehive-blue;
  //border: 5px solid red;
  //height: 22vw;
}

// Single remote user - preview video
.singleRemoteUserLocalMediaDiv video {
  width: 100%;
  z-index: 200;
}

// Single remote user - remote media container
.singleRemoteUserRemoteMediaDiv {
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
  z-index: 2000;
  //overflow: hidden;
  background-color: $color-beehive-blue;
  //border: 5px solid green;
  //height: 66vw;
}

// Single remote user - remove media video
.singleRemoteUserRemoteMediaDiv video {
  width: 100%;
  z-index: 200;
}

.textChatArea {
  position: absolute;
  top: 670px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 920px;
}
