.chatWindow {
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 400px;
  width: 100%;
  margin: 0.5em;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
}

.chatWindow .MessageList {
  box-sizing: border-box;
  flex: 1;
}

.chatWindow .MessageForm {
  background: #eee;
  border-top: 1px solid #ccc;
  border-radius: 0 0 3px 3px;
  box-sizing: border-box;
  flex: 0 0 30px;
}
