@import "./../shared.scss";

.onDemandVideoAspectDiv {
  margin-left: 5%;
  margin-top: 5%;
  width: 90%;
  padding-bottom: 56.25%;
  position: relative;
}

.onDemandVideoDiv {
  position: absolute;
  width: 100%;
  height: 100%;
}

.infoArea {
  margin:0% 5% 5% 5%;
}

.contentTitle {
  color: $color-gallery-feature-text;
  font-size: 1.5em;
  font-weight: 700;
}

.onDemandPresenterNameText {
  font-size: 1.3em;
  color: $color-gallery-feature-text;
}

.aboutTextDiv {
  margin-top: 20px;
}

.aboutText {
  font-size: 1em;
}

.pdfIcon {
  width: 225px;
  height: 125px;
  margin-left: 112px;
  margin-top: 62px;
}
