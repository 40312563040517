@import "./../shared.scss";



.modalTitleBar {
  background-color: $color-arm-dark-blue;
  padding-top: 12px;
  padding-bottom: 0px;
  padding-left: 30px;
  color: $color-modal-title-bar-text;
  background-size: 100%;
  height: 58px;
  background-size: auto 100%;
  background-repeat: no-Repeat;
  background-position: top right;
}

.modalPageTitle {
  font-size: 1.7em;
  font-weight: 700;
}

@media (max-width: 699px) {
  .modalPageTitle {
    font-size: 1.5em;
    margin-bottom: 100px;
  }

  .modalTitleBar {
    padding-bottom: 10px;
  }

  .modalClose {
    font-size: 0em;

  }
}

@media (max-width: 600px) {
  .modalPageTitle {
    font-size: 1.3em;
    margin-bottom: 100px;
  }
}

// Scroll element
// Fill whole of remaining space
.scrollableArea {
  // Scale to fill available space
  flex-grow: 1;
  margin-top: 0%;
  margin-left: 0%;
  margin-right: 0%;
  position: relative;
  overflow-y: auto;
  padding-top: 0%;
}

.modalContentArea {
  padding: 40px;
  padding-top: 40px;
  padding-bottom: 100px;
  color: $color-arm-black;
}

.modalContentArea p {
  font-size: 1.2em;
}





// Two column responsive
.columnContainer {
  display: flex;
  margin-top: 30px;
}

.leftProfileColumn {
  margin: 0px;
  margin-top: 20px;
  width: 50%;
  padding-left: 40px;
  padding-right: 40px;
  border-right: 1px solid grey;
}

.rightProfileColumn {
  margin: 0px;
  margin-top: 20px;
  width: 50%;
  padding-left: 40px;
  padding-right: 40px;
}

// Single column < 800px
@media (max-width: 800px) {
  .columnContainer {
    display: block;
  }

  .leftProfileColumn {
    width: 100%;
    margin: 0;
  }

  .rightProfileColumn {
    width: 100%;
    margin: 0;
  }
}

















// Two column specific

.twoColumnTable {
  margin-top: 30px;
}

.tableCell {
  padding-left: 40px;
  padding-right: 40px;
  vertical-align: top;
}

.tableDividerCell {
  border-right: 2px solid grey;
}

// Video booth specific
.exampleVideoFrame {
  width: 100%;
}

.recordButton {
  width: 200px;
  text-align: left;
  font-weight: 700;
}

.submitButton {
  margin-top: 10px;
  float: right;
  width: 110px;
  font-weight: 700;
}

.buttonArrow {
  width: 15px;
  float: right;
  margin-left: 10px;
}

.qrNoonahImage {
  width: 150px;
  margin-top: 30px;
  margin-bottom: 3px;
}

.recordOptionsTable {
  margin-top: 20px;
  width: 100%;
}

.qrTableCell {
  text-align: center;
}

// Agenda specific

.agendaContent {
  font-size: 1.3em;
  padding-left: 5.2em;
  text-indent: -5.2em;

}

.dayTitleText {
  color: #002b49;
  font-weight: 700;
  font-size: 1.9em;
}

.timeText {
  color: #ff6b00;
}

.bulletText {
  padding-left: 5px;
}

.agendaBulletPoints {
  margin-left: 90px;
}

// Host specific
.hostProfilePicture {
  width: 170px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.hostTitleField {
  font-size: 1.2em;
  font-weight: 700;
  color: #1b2f4a;
}

.hostAnswerField {
  font-size: 1.2em;
}

// Attendee wall/Padlet specific
.padletEmbed {
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #f4f4f4;
}

.padletEmbediFrame {
  width: 111.7%;
  height: 1000px;
  display: block;
  padding: 0;
  margin: 0;
  zoom: 0.9;
  -moz-transform: scale(0.9);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.9);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.9);
  -webkit-transform-origin: 0 0;
}

.embedModalContentArea {
  padding: 0px;
  padding-top: -20px;
  padding-bottom: 0px;
  color: #002b49;
}