@import "./../shared.scss";
@import "./../sharedStyles/SharedGallery.module.scss";

.infoText {
  position: absolute;
  top: 13px;
  width: 50%;
  right: 0px;
  text-align: right;
}

@media (max-width: 780px) {
  .infoText {
    display: none;
  }
}

.contentContainer {
  position: absolute;
  padding: 2%;
  left: 0;
  right: 0;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 170px;
  padding-bottom: 200px;
}

.contentContainer h1 {
  color: $color-title-text;
}

// Column Structure
// 2 Column > 800px;
.columnContainer {
  display: flex;
}

.formColumn {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 45%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

// Single column < 800px
@media (max-width: 800px) {
  .columnContainer {
    display: block;
  }
  .formColumn {
    width: 100%;
    margin: 0;
  }
}

.meetingCategoryHeader {
  background-color: $color-modal-title-bar-background;
  color: white;
  padding: 15px;
  height: 80px;
}





// Confirmed area
.confirmedContainer {
  width: 100%;
  background-color: $color-background;
  height: 780px;
  box-shadow: 10px 10px 25px 8px grey;
}

.confirmedMeetingsScrollDiv {
  height: 700px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.confirmedMeetingRow {
  width: 100%;
  //height: 100px;
  padding: 10px;
}

.confirmedMeetingItem {
  background-color: $color-agenda-item-background;
  display: flex;
  border-radius: 0 10px 10px 0;
  margin-bottom: 6px;
}

.sideBorderArea {
  border-radius: 10px 0 0 10px;
  background-color: $color-beehive-purple;
  flex:1;
}

.timeAndTitleArea {
  flex: 20;
  padding: 10px;
}

.timeText {
  font-size: 20px;
  font-weight: 700;
  color: $color-agenda-text;
}

.titleText {
  font-size: 20px;
  color: $color-meeting-text;
}

.spacerArea {
  width: 1px;
  background-color: $color-agenda-item-spacer;
  padding: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.joinArea {
  flex: 2;
  //padding-left: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.joinButton {
  margin-left: 20px;
  margin-right: 20px;
}


// Meetings received
.receivedDiv {
  width: 100%;
  height: 380px;
  background-color: $color-background;
  box-shadow: 10px 10px 25px 8px grey;
}

.receivedScrollDiv {
  height: 300px;
  overflow-y: scroll;
}

.profilePicture {
  padding: 15px;
  //display: inline-block;
  float: left;
}

.requestText {
  padding-top: 0px;
  padding-right: 20px;
  //display: inline-block;
  color: $color-meeting-text;
  word-wrap: normal;
  //width: 300px;
  float: left;
  vertical-align: top;
}

.responseButton {
  margin: 5px;
}

.awaitingDiv {
  margin-top: 20px;
  width: 100%;
  height: 380px;
  background-color: $color-background;
  box-shadow: 10px 10px 25px 8px grey;
}

.awaitingScrollDiv {
  height: 300px;
  overflow-y: scroll;
}
