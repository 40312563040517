@import "./../shared.scss";
@import "./Pre-Login.module.scss";

.confirmUserEmailModal {
  @extend .preLoginModal;
  z-index: 3000;
}

.confirmUserEmailModalInnerDiv {
  @extend .preLoginModalInnerDiv;
}


.confirmationStatusText {
  //text-align: center;
}
