@import "./../shared.scss";

.exitHoverHotspot {
  position: absolute;
  opacity: 0;
  top: 15%;
  left: 1%;
  width: 5%;
  height: 10%;
  z-index: 5000;
}

.titleBar {
  position: absolute;
  top: 18.5%;
  left: 7%;
  width: 73.6%;
  background-color: $color-modal-title-bar-background;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 30px;
  color: $color-modal-title-bar-text;
  background-size: 100% 100%;
}

.pageTitle {
  display: inline;
  color: white;
}

.onDemandItemsArea {
  position: absolute;
  background-color: white;
  padding: 1%;
  left: 8%;
  width: 72%;
  top: calc(10.5vw + 80px);
  height: calc(41vw - 80px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  z-index: 3000;
}

.contentItem {
  position: relative;
  width: 500px;
  height: 140px;
}

.contentVideoArea {
  position: absolute;
  margin: 20px;
  width: 100px;
  pointer-events: none;
}

.onDemandVideoDiv {
  width: 160px;
  height: 90px;
}

.contentInfoArea {
  position: absolute;
  margin: 16px;
  margin-left: 200px;
  width: 240px;
  overflow-y: auto;
}

.contentTitle {
  font-size: 1.1em;
}

.speakerName {
  font-size: 1em;
}

.pdfIcon {
  width: 140px;
  height: 90px;
}
