@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
}

.exitHoverHotspot {
  left: 30%;
  width: 20%;
  top: 40%;
  height: 20%;
}

.stand1ClickArea {
  background-color: purple;
  left: 00%;
  width: 15%;
  top: 20%;
  height: 70%;
}

.stand2ClickArea {
  background-color: orange;
  left: 15%;
  width: 15%;
  top: 20%;
  height: 50%;
}

.stand3ClickArea {
  background-color: blue;
  left: 50%;
  width: 15%;
  top: 20%;
  height: 70%;
}

.stand4ClickArea {
  background-color: yellow;
  left: 65%;
  width: 15%;
  top: 20%;
  height: 70%;
}

.stand5ClickArea {
  background-color: green;
  left: 80%;
  width: 20%;
  top: 20%;
  height: 70%;
}

.titleBar {
  background-color: $color-modal-title-bar-background;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 30px;
  color: $color-modal-title-bar-text;
  background-position: 20px -2px;
  height: 60px;
}

.pageTitle {
  display: inline;
}

.modalClose {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  background-color: $color-modal-title-bar-background;
  background-color: Transparent;
  border: 0px;
  color: $color-modal-title-bar-text;
}

.closeIcon {
  margin-left: 20px;
  width: 20px;
  margin-top: -3px;
}

.detailImage {
  width: 100%;
}

.imageNavContainer {
  width: 100%;
  height: 100%;
  position: absolute;
}

.detail1ChatButton {
  position: absolute;
  left: 38%;
  top: 53%;
  width: 16%;
  height: 7%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail1UnifiedButton {
  position: absolute;
  left: 4%;
  top: 65%;
  width: 19%;
  height: 28%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail1PostOfficeButton {
  position: absolute;
  left: 24%;
  top: 65%;
  width: 17%;
  height: 28%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail1UniteButton {
  position: absolute;
  left: 42%;
  top: 65%;
  width: 17%;
  height: 28%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail1EraButton {
  position: absolute;
  left: 59.5%;
  top: 65%;
  width: 17%;
  height: 28%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail1WebsiteButton {
  position: absolute;
  left: 78%;
  top: 65%;
  width: 17%;
  height: 28%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail2VideoButton {
  position: absolute;
  left: 19%;
  top: 29%;
  width: 62%;
  height: 46%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail2ResponseButton {
  position: absolute;
  left: 10%;
  top: 90%;
  width: 40%;
  height: 8%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail2ImpactButton {
  position: absolute;
  left: 50%;
  top: 90%;
  width: 40%;
  height: 8%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail3ChatButton {
  position: absolute;
  left: 41%;
  top: 48%;
  width: 16%;
  height: 7%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail3EducationButton {
  position: absolute;
  left: 1%;
  top: 49%;
  width: 38%;
  height: 8%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail3KitsButton {
  position: absolute;
  left: 1%;
  top: 60%;
  width: 22%;
  height: 38%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail3SchoolButton {
  position: absolute;
  left: 24%;
  top: 60%;
  width: 22%;
  height: 38%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail3BooksButton {
  position: absolute;
  left: 49%;
  top: 60%;
  width: 22%;
  height: 38%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail3OnlineButton {
  position: absolute;
  left: 74%;
  top: 60%;
  width: 22%;
  height: 38%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail4ChatButton {
  position: absolute;
  left: 79%;
  top: 57%;
  width: 16%;
  height: 7%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail4CollaborationButton {
  position: absolute;
  left: 47%;
  top: 50%;
  width: 50%;
  height: 6%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail4BlogsButton {
  position: absolute;
  left: 59%;
  top: 57%;
  width: 20%;
  height: 6%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail4InternshipButton {
  position: absolute;
  left: 2%;
  top: 20%;
  width: 28%;
  height: 35%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail4WinogradButton {
  position: absolute;
  left: 2%;
  top: 74.5%;
  width: 28%;
  height: 6%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail4StemButton {
  position: absolute;
  left: 2%;
  top: 80.5%;
  width: 28%;
  height: 6%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail5ChatButton {
  position: absolute;
  left: 2%;
  top: 90.5%;
  width: 15%;
  height: 7%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail5QuizButton {
  position: absolute;
  left: 18%;
  top: 90.5%;
  width: 28%;
  height: 7%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail5EnablementButton {
  position: absolute;
  left: 2%;
  top: 75%;
  width: 50%;
  height: 7%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail5FlexibleButton {
  position: absolute;
  left: 2%;
  top: 83%;
  width: 50%;
  height: 7%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}

.detail5VideoButton {
  position: absolute;
  left: 58%;
  top: 68%;
  width: 40%;
  height: 30%;
  background-color: red;
  opacity: 0;
  cursor: pointer;
}
