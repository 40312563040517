@import "./../shared.scss";

.customScrollableAreaNoPadding {
  // Scale to fill available space
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
  margin: 0% 0% 2% 0%;
  padding-top: 2%;
}


// Two column responsive
.columnContainer {
    display: flex;
    margin-top: 10px;
  }
  
  .leftProfileColumn {
    margin: 0px;
    margin-top: 0px;
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
    //border-right: 1px solid grey;
    text-align: center;
  }
  
  .rightProfileColumn {
    margin: 0px;
    margin-top: 0px;
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
  
  // Single column < 800px
  @media (max-width: 800px) {
    .columnContainer {
      display: block;
    }
    .leftProfileColumn {
      width: 100%;
      margin: 0;
      border-right: none;
    }
    .rightProfileColumn {
      width: 100%;
      margin: 0;
    }
  }

.roundtableArea {
  position: relative;
  width: 100%;
}

  .roundtableBackground {
    position: relative;
    width: 100%;
  }

  .roundtableOverlayText {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    width:60%;
    font-size: 2em;

  }