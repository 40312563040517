@import "./../shared.scss";

.proportionalDiv {
  padding-top: 53.7%;
  width: 100%;
  height: 0;
}

.backgroundImage {
  width: 100%;
  position: absolute;
  top: 0px;
}

.textEntry {
  width: 350px;
  background: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0);
  color: white;
}

@mixin holdingButton() {
  position: absolute;
  background-color: red;
  opacity: 0;
  left: 47.5%;
  width: 21%;
  height: 5%;
  cursor: pointer;
}

@mixin holdingTick() {
  position: absolute;
  left: 68.8%;
  height: 2.6%;
  cursor: pointer;
}

$tickSpacing: 5%;
$tickOffset: 81.3%;

.buttonProfile {
  @include holdingButton;
  top: 80%;
}

.tickProfile {
  @include holdingTick;
  top: $tickOffset;
}

.buttonAgenda {
  @include holdingButton;
  top: 85%;
}

.tickAgenda {
  @include holdingTick;
  top: $tickOffset + $tickSpacing * 1;
}

.buttonVideo {
  @include holdingButton;
  top: 90.5%;
}

.tickVideo {
  @include holdingTick;
  top: $tickOffset + $tickSpacing * 2;
}

.buttonNetworking {
  @include holdingButton;
  top: 80%;
  left: 72%;
}

.tickNetworking {
  @include holdingTick;
  top: $tickOffset;
  left: 93.4%;
}


.buttonOnDemand {
  @include holdingButton;
  top: 85%;
  left: 72%;
}

.tickOnDemand {
  @include holdingTick;
  top: $tickOffset + $tickSpacing;
  left: 93.4%;
}

.buttonHybrid {
  @include holdingButton;
  top: 90.5%;
  left: 72%;
  
}

.tickHybrid {
  @include holdingTick;
  top: $tickOffset + $tickSpacing * 2;
  left: 93.4%;
}


.landingPageMobileTextBold {
  color: $color-arm-black;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  font-weight: 700;
}

.landingPageMobileText {
  color: $color-arm-black;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.customMobileTitleText {
  margin-bottom: 10px;
}

// Single column < 800px
@media (max-width: 600px) {
  .customMobileTitleText {
    font-size: 2rem;
  }
}

@media (max-width: 400px) {
  .customMobileTitleText {
    font-size: 1.5rem;
  }
}

@media (max-width: 300px) {
.customMobileTitleText {
    font-size: 1rem;
  }
}