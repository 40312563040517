@import "./../shared.scss";

.fileInput {
  //display: block;
  //width: 100%;
}

// Grid Structure
// 2 Column > 800px;
.profileGridContainer {
  display: grid;
  grid-template-columns: 50% 50%;
}

.profileGridElement {
  margin: 10px;
}

// Single column < 800px
@media (max-width: 800px) {
  .profileGridContainer {
    grid-template-columns: 100%;
  }
}

// Hide standard file upload button
input[type="file"] {
  //display: none;
}

.uploadButton {
  //width: 100px;
  height: 40px;
  margin-left: 60px;
}

.dropDownEntry {
  background: rgba(255, 255, 255, 0.3);
  border: 1px grey solid;
  color: $color-login-text;
  height: 30px;
}
