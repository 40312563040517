@import "./../shared.scss";

// Column Structure
// 2 Column > 800px;
.columnContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.formColumn {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 50%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  height: 100%;
}

// Single column < 800px
@media (max-width: 800px) {
  .columnContainer {
    display: block;
  }
  .formColumn {
    width: 100%;
    margin: 0;
  }
}

.answeredDiv {
  width: 100%;
  background-color: white;
  height: calc(100% - 60px);
}

.questionHeader {
  background-color: $color-beehive-blue;
  width: 100%;
  height: 40px;
  color: white;
  padding: 10px;
}

.questionHeaderTitle {
  font-size: 1em;
}

.answerScrollArea {
  overflow-y: scroll;
  height: calc(100% - 50px);
  padding: 5%;
}

.unansweredDiv {
  width: 100%;
  background-color: white;
  height: calc(70% - 100px);
}

.enterQuestionDiv {
  width: 100%;
  background-color: white;
  margin-top: 20px;
  height: calc(30% + 20px);
}

.newQuestion {
  margin-top: 0px;
  margin-left: 5%;
  width: 60%;
}

.submitButton {
  margin-left: 5%;
}

.questionText {
  color: $color-dark-grey;
  font-size: 1em;
}

.answerText {
  color: $color-light-blue;
  font-size: 1em;
}

.openingTimes {
  margin-top: 10px;
  margin-left: 5%;
  color: $color-dark-grey;
  font-size: 1em;
}

@media (max-height: 600px) {
  body {
    background-color: lightblue;
  }
}

@media (max-height: 600px) {
  .openingTimes {
    visibility: hidden;
  }
}
