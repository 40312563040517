@import "./../shared.scss";

.background {
  background-color: grey;
}

.analyticsItem {
  position: absolute;
  color: white;
  width: 9%;
  text-align: center;
  font-size: 2.5vw;
}

$regRowTop: 16.5%;
$meetingsRowTop: 46%;
$questionsRowTop: 75%;

$leftOffset: 5%;
$leftSpacing: 11.5%;

$left3ColumnOffset: 6.3%;
$left3ColumnSpacing: 16.1%;

$rightOffset: 54%;
$rightSpacing: 11%;

$rightRow1: 18.4%;
$rightRow2: 47%;
$rightRow3: 74.9%;

.lobbyHoverHotspot {
  //background-color: red;
  top: 84%;
  left: 46%;
  width: 8%;
  height: 13%;
}

.searchHoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
}

.searchHoverHotspot:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

.totalUsers {
  left: $leftOffset;
  top: $regRowTop;
}

.awaitingConfirmation {
  left: $leftOffset + $leftSpacing;
  top: $regRowTop;
}

.awaitingApproval {
  left: $leftOffset + 2 * $leftSpacing;
  top: $regRowTop;
}

.approved {
  left: $leftOffset + 3 * $leftSpacing;
  top: $regRowTop;
}

.requestedMeetings {
  left: $leftOffset;
  top: $meetingsRowTop;
}

.declinedMeetings {
  left: $leftOffset + $leftSpacing;
  top: $meetingsRowTop;
}

.pendingMeetings {
  left: $leftOffset + 2 * $leftSpacing;
  top: $meetingsRowTop;
}

.confirmedMeetings {
  left: $leftOffset + 3 * $leftSpacing;
  top: $meetingsRowTop;
}

.totalQuestions {
  left: $left3ColumnOffset;
  top: $questionsRowTop;
}

.unansweredQuestions {
  left: $left3ColumnOffset + $left3ColumnSpacing;
  top: $questionsRowTop;
}

.answeredQuestions {
  left: $left3ColumnOffset + 2 * $left3ColumnSpacing;
  top: $questionsRowTop;
}

.totalVisits {
  left: $rightOffset;
  top: $rightRow1;
}

.plenaryVisits {
  left: $rightOffset + $rightSpacing;
  top: $rightRow1;
}

.breakout1Visits {
  left: $rightOffset + 2 * $rightSpacing;
  top: $rightRow1;
}

.breakout2Visits {
  left: $rightOffset + 3 * $rightSpacing;
  top: $rightRow1;
}

.breakout3Visits {
  left: $rightOffset;
  top: $rightRow2;
}

.breakout4Visits {
  left: $rightOffset + $rightSpacing;
  top: $rightRow2;
}

.breakout5Visits {
  left: $rightOffset + 2 * $rightSpacing;
  top: $rightRow2;
}

.breakout6Visits {
  left: $rightOffset + 3 * $rightSpacing;
  top: $rightRow2;
}

.breakout7Visits {
  left: $rightOffset;
  top: $rightRow3;
}

.exhibitionVisits {
  left: $rightOffset + $rightSpacing;
  top: $rightRow3;
}

.posterVisits {
  left: $rightOffset + 2 * $rightSpacing;
  top: $rightRow3;
}

.networkingLoungeVisits {
  left: $rightOffset + 3 * $rightSpacing;
  top: $rightRow3;
}
