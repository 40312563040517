@import "./../shared.scss";
@import "./../sharedStyles/SharedGallery.module.scss";

.profileSegmentsArea {
  padding: 0%;
  padding-top: 0;
  width: 100%;
  position: absolute;
  top: calc(260px + 2%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  z-index: 3000;
  justify-items: center;
}

.exhibitionStandLink {
  text-decoration: none !important;
}

.jobContainer {
  background-color: $color-gallery-item-background;
  margin-top: 10px;
  margin-bottom: 50px;
  width: 350px;
  height: 400px;
  border-width: 2px;
  border-color: $color-gallery-item-border;
  border-style: solid;
  z-index: 3220;
  box-shadow: 10px 10px 25px 8px grey;
  padding: 20px;
  position: relative;
}

.companyLogo {
  margin-left: 2px;
  width: 300px;
  height: 100px;
  left: 0;
  right: 0;
}

.jobNameContainer {
  width: 100%;
  height: 120px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 20px;
  z-index: 3221;
  color: black;
}

.standNameText,
.standNameText:hover {
  color: black;
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 20px;
}

.jobSummaryText,
.jobSummaryText:hover {
  color: black;
  font-size: 1.2em;
}

.visitStandButton {
  width: 150px;
  height: 45px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  z-index: 3201;
  font-size: 1.2em;
  padding-top: 7px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  bottom: 25px;
}

.profileIcon {
  width: 40px;
  margin-top: 20px;
  margin-right: 20px;
  float: left;
}

.profileParameterTitles {
  color: $color-gallery-feature-text;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 0.1em;
  //margin-left: 60px;
  margin-right: 40px;
}

.profileParameterAnswer {
  margin-left: 60px;
  margin-top: 0.1em;
  margin-bottom: 0.3em;
  //margin-left: 0px;
}

.noIconProfileParameterAnswer {
  //margin-left: 60px;
  margin-top: 0.1em;
  margin-bottom: 0.3em;
  margin-left: 0px;
}

// Scroll element
// Fill whole of remaining space
.scrollableArea {
  // Scale to fill available space
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.columnContainer {
  display: flex;
}

.leftProfileColumn {
  width: 30%;
  padding: 0px 20px 20px 20px;
}

.rightProfileColumn {
  width: 60%;
  padding: 10px;
  padding: 0px 20px 20px 20px;
}

// Single column < 800px
@media (max-width: 800px) {
  .columnContainer {
    display: block;
  }
  .leftProfileColumn {
    width: 100%;
    margin: 0;
  }
  .rightProfileColumn {
    width: 100%;
    margin: 0;
  }
}

.titleArea {
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 0px;
}

.meetingSlotsList {
  width: 500px;
  display: table;
}

.meetingSlotsButton {
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 85px;
  height: 65px;
}

.meetingSlotsButton:checked {
  margin: 20px;
}

.modalClose {
  float: right;
}

// CSS For Advanded Search Pop Up
@keyframes :global(fadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.advancedSearchBox :global {
  background-color: $color-login-background;
  color: $color-login-text;
  position: absolute;
  top: 200px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  height: 600px;
  width: 700px;
  z-index: 30000;
  animation: 1s ease-out 0s fadeIn;
  z-index: 300000;
  box-shadow: 10px 10px 25px 8px grey;
  border: 2px solid #000;
}

@media (max-width: 700px) {
  .advancedSearchBox {
    width: 90%;
    left: 5%;
    height: 1000px;
  }
}

.advancedSearchTitleBar {
  position: absolute;
  width: 100%;
  background-color: $color-beehive-blue;
  color: white;
  height: 55px;
  padding: 9px;
}

.advancedSearchModalClose {
  float: right;
  margin-right: 20px;
  margin-top: 0px;
  background-color: transparent;
  border: 0px;
  color: $color-modal-title-bar-text;
}

.closeIcon {
  margin-right: -10px;
  width: 20px;
  margin-top: -10px;
}

.searchArea {
  position: absolute;
  top: 60px;
  padding: 20px;
  width: 100%;
}

.advancedSearchButtonBar {
  position: absolute;
  top: 210px;
  margin-bottom: 10px;
  height: 50px;
  width: 100%;
  text-align: center;
}

.advancedSearchButton {
}

.multiRowButtonGroup {
  width: 100%;
  display: table;
}
