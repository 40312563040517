@import "./../shared.scss";

.hoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.visibleHoverHotspot {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.visibleHoverHotspot:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

.zoomHoverHotspot {
  position: absolute;
  top: 30.9%;
  left: 30.6%;
  width: 30.5%;
  height: 30%;
}

.lobbyHoverHotspot {
  top: 60.2%;
  left: 1.45%;
  width: 18.4%;
}

.attendeesHoverHotspot {
  top: 33.69%;
  left: 22.1%;
  width: 10.1%;
}

.joinMeetingHoverHotspot {
  top: 71.1%;
  left: 23.7%;
  width: 27.8%;
}

.gamingHoverHotspot {
  top: 46.6%;
  left: 42.6%;
  width: 13.5%;
}

.ondemandHoverHotspot {
  top: 52.15%;
  left: 62.75%;
  width: 25.15%;
}

.hoverHotspot:hover {
  opacity: 0;
  transition: opacity 0.5s;
}

.videoDiv {
  position: absolute;
  top: 10%;
  left: 8%;
  width: 87%;
  height: 87%;
  opacity: 1;
}

.iFrameClass {
  height: 100%;
  width: 100%;
  border: 0px;
}
