@import "./../shared.scss";

.iFrameAspectDiv {
  width: 90%;
  margin: 5%;
  padding-bottom: 56.25%;
  position: relative;
}

.iFrameDiv {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slidoDiv {
  width: 90%;
  margin: 5%;
}
