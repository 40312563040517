@import "./../shared.scss";

.goToPresentation {
  position: absolute;
  opacity: 0.5;
  //background-color: red;
  top: 30%;
  left: 90%;
  width: 10%;
  height: 70%;
  z-index: 5000;
}

.leavePresentation {
  position: absolute;
  opacity: 0.5;
  //background-color: green;
  top: 8%;
  left: 0%;
  width: 8%;
  height: 15%;
  z-index: 5000;
  cursor: pointer;
}

.exitHoverHotspot {
  position: absolute;
  opacity: 0;
  //background-color: blue;
  top: 45%;
  left: 50%;
  width: 25%;
  height: 30%;
  z-index: 5000;
}

.iFrameDiv {
  position: absolute;
  top: 14.5%;
  left: 5%;
  width: 75.3%;
  height: 76.5%;
}

.iFrameClass {
  height: 100%;
  width: 100%;
  border: 0px;
}

.slidoDiv {
  position: absolute;
  width: 17%;
  height: 72.5%;
  left: 81.9%;
  top: 15.7%;
}

.largeBreakoutiFrameDiv {
  position: absolute;
  top: 8%;
  left: 5.5%;
  width: 90%;
  height: 91%;
}
