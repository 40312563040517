@import "./../shared.scss";

.MessageForm {
  display: flex;
  background: white;

  background: white;
  border-top: 1px solid $color-dark-grey;
  border-radius: 0 0 3px 3px;
  box-sizing: border-box;
  flex: 0 0 30px;
}

.MessageForm .input-container {
  flex: 1;
  margin: 1px;
}

.MessageForm .button-container {
  flex: 0 0 6em;
  margin: 1px 1px 1px 0;
}

.MessageForm input {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: inherit;
  height: 100%;
  outline: none;
  width: 100%;
}

.MessageForm button {
  background: $color-button-success;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: inherit;
  height: 100%;
  outline: none;
  width: 120px;
}

.MessageForm input {
  padding: 0 0 0 4px;
}
